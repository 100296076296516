import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SingleValue } from 'react-select';

import AccountSpecificInfo from '../components/AccountSpecificInfo';
import TicketTypeSelector from '../components/TicketTypeSelector';
import TicketUpgradeSelector from '../components/TicketUpgradeSelector';
import TicketGrid from './../components/TicketGrid';
import ClubDetails from './../components/ClubDetails'
import Posters from './../components/Posters';
import Finance from './../components/Finance';
import CharityDonations from './../components/CharityDonations';
import Waitlist from './../components/Waitlist';
import useAcceptRejectModal from '../components/AcceptCancelModal';

import { useAuth } from '../contexts/auth';
import { AccountStatus } from '../models/AccountStatus';
import { Ticket } from '../models/Ticket';
import * as styles from './../styles/homepage';
import { AccountRules, getAccountRules } from '../helpers/rules';

const Homepage = () => {
  const { request, info } = useAuth();
  const accountRules = getAccountRules(info.status as AccountStatus, info.graduationYear);
  const applicationsOpen = (new Date()) <= accountRules.applicationDeadline;

  const [appTickets, setAppTickets] = useState<Ticket[]>([]);
  const [allTickets, setAllTickets] = useState<Ticket[]>([]);
  const [accTickets, setAccTickets] = useState<Ticket[]>([]);

  const [ticketType, setTicketType] = useState<SingleValue<{ value: string; label: string; }>>(null);
  const [fastTrack, setFastTrack] = useState<SingleValue<{ value: string; label: string; }>>(null);
  const [dining, setDining] = useState<SingleValue<{ value: string; label: string; }>>(null);
  const [breakfast, setBreakfast] = useState<SingleValue<{ value: string; label: string; }>>(null);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState('');


  const getCurrentTickets = async () => {
    const response = await request('/tickets', 'GET');
    const data = await response.json();

    if (response.ok) {
      setAppTickets(data.applied);
      setAllTickets(data.allocated);
      setAccTickets(data.accepted);
    }
  }

  const getAppFromRules = (rules: AccountRules) => {
    return `${rules.maxSingles} single(s), and up to ${rules.maxDoubles} double(s)`;
  }


  useEffect(() => {
    getCurrentTickets();
  }, []);

  const resetForm = () => {
    setTicketType(null);
    setFastTrack(null);
    setDining(null);
    setBreakfast(null);
  }

  const submitForm = async () => {
    setIsLoading(true);
    const response = await request('/tickets/apply', 'POST', {
      type: ticketType!.value,
      upgrades: {
        fasttrack: fastTrack?.value === 'true',
        dining: dining?.value === 'true',
        breakfast: breakfast?.value === 'true',
      }
    });
    setIsLoading(false);

    if (response.ok) {
      getCurrentTickets();
      resetForm();
      setShowModal(false);
    } else {
      let d = await response.json();
      setModalError(d.error);
    }
  }

  const removeTicket = async (index?: number) => {
    const response = await request(`/tickets/apply/${index}`, 'DELETE');
    if (response.ok) {
      getCurrentTickets();
    }
  }

  const closeModal = () => {
    resetForm();
    setShowModal(false);
  }

  const [AcceptModal, openAcceptModal, closeAcceptModal] = useAcceptRejectModal();
  const [RejectModal, openRejectModal, closeRejectModal] = useAcceptRejectModal();
  const [selectedTicket, setSelectedTicket] = useState<number|null>(null);

  const acceptTicketAllocation = async (index: number) => {
    const response = await request(`/tickets/application/accept/${index}`, 'POST');
    if (response.ok) {
      setSelectedTicket(null);
      getCurrentTickets();
      closeAcceptModal();
    }
  }

  const rejectTicketAllocation = async (index: number) => {
    const response = await request(`/tickets/application/reject/${index}`, 'POST');
    if (response.ok) {
      setSelectedTicket(null);
      getCurrentTickets();
      closeRejectModal();
    }
  }

  return <>
    {showModal && <>
      <styles.ModalWrapper>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>Apply for tickets</h2>
            <styles.CloseButton onClick={() => closeModal()}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            <h3>Ticket details:</h3>

            <TicketTypeSelector
              accountRules={accountRules}
              appliedTickets={appTickets}
              setTicketType={setTicketType}
            />

            <TicketUpgradeSelector
              accountRules={accountRules}
              setFastTrack={setFastTrack}
              setDining={setDining}
              setBreakfast={setBreakfast}
            />

            {modalError && <p className="error">{modalError}</p>}

            <styles.SubmitButton
              color={'primary'}
              onClick={() => submitForm()}
              disabled={isLoading || ticketType === null}
            >
              {isLoading? "Submitting..." : "Submit"}
            </styles.SubmitButton>


          </styles.ModalBody>
        </styles.ModalBox>
      </styles.ModalWrapper>

    </>}

    <styles.PageWrapper>
      <styles.Header id="header">
        <div className="info">
          <h1>St John's College May Ball Ticketing</h1>
          <p>Logged in as <b>{info.email}</b></p>
        </div>
        <Link to={'/logout'}>Sign Out</Link>
      </styles.Header>

      {
        accTickets.length > 0 && <>
          <Finance />
        </>
      }


      <styles.TwoColumn>
        <Posters />

        <CharityDonations />
      </styles.TwoColumn>

      

      { (info.status === '1888' && !info.club_verification) && <ClubDetails /> }

      <styles.Apply id="apply">
        <h3>Apply for Tickets for the ball.</h3>
        <p>Based on your {accountRules.accountStatus} status, you can apply for <b>{getAppFromRules(accountRules)}</b>.</p>
        {
          applicationsOpen ?
          (
            appTickets.length < accountRules.maxSingles + accountRules.maxDoubles ?
            <styles.SubtleButton onClick={() => setShowModal(true)}>Apply</styles.SubtleButton> :
            <p>You have already applied for the maximum number of tickets. Should you wish to edit an applied-for ticket please remove it and reapply.</p>
            )
            :
            <p>Applications for {accountRules.accountStatus} accounts are now <b>closed</b>. We will email you once allocations have been made.</p>
          }
      </styles.Apply>

      <AccountSpecificInfo accountRules={accountRules} />
      

      {accountRules.applicationDeadline < new Date() ? <>

        <Waitlist />
      </> : <>
      <TicketGrid
        tickets={appTickets} ticketGroup={'applied'}
        buttons={[
          {text: 'Remove', fn: removeTicket}
        ]}
      />
      
      </>}

      <TicketGrid
        tickets={allTickets} ticketGroup={'allocated'}
        buttons={[
          // {text: 'Accept', fn: (i) => {setSelectedTicket(i); openAcceptModal();}},
          // {text: 'Reject', fn: (i) => {setSelectedTicket(i); openRejectModal();}}
        ]}
        />
      <AcceptModal
        title={'Accept Ticket Allocation'}
        acceptFn={() => acceptTicketAllocation(selectedTicket!)}
        rejectFn={() => {closeAcceptModal(); setSelectedTicket(null);}}
        >
        <h3>Accept your ticket allocation.</h3>
        <p>By accepting this, as per the <a href="https://auth.mayball.org/assets/2022-sjcmb-terms.pdf" target="_blank">Terms and Conditions</a>, you <b>commit to pay for it in full</b>.</p>
        <p>Once it has been accepted, cancellation will lead to a requirement to pay a cancellation fee.</p>
        <div className={'divider'} />
        <h3>Ticket details</h3>
        { selectedTicket !== null ? <>
          <p><b>{allTickets[selectedTicket].type[0].toUpperCase() + allTickets[selectedTicket].type.slice(1)}</b> ticket. Admits {allTickets[selectedTicket].type === "single" ? '1 person' : '2 people'}.</p>
          <br />
          <p><b>Upgrades:</b></p>
          <p>Fast Track: <b>{allTickets[selectedTicket].upgrades.fasttrack ? 'Yes' : 'No'}</b></p>
          <p>Dining: <b>{allTickets[selectedTicket].upgrades.dining ? 'Yes' : 'No'}</b></p>
          <p>Breakfast: <b>{allTickets[selectedTicket].upgrades.breakfast ? 'Yes' : 'No'}</b></p>
          <br />
          <p>Price: <b>£{allTickets[selectedTicket].price}</b></p>
        </> : null}
      </AcceptModal>

      <RejectModal
        title={'Reject Ticket Allocation'}
        acceptFn={() => rejectTicketAllocation(selectedTicket!)}
        rejectFn={() => {closeRejectModal(); setSelectedTicket(null);}}
      >
        <h3>Reject your ticket allocation.</h3>
        <p>By rejecting this, as per the <a href="https://auth.mayball.org/assets/2022-sjcmb-terms.pdf" target="_blank">Terms and Conditions</a>, <b>you will lose this ticket permanently</b> and it will be reallocated to someone else.</p>
        <p>Once it has been rejected there is no way for it to be reallocated to you.</p>
        <div className={'divider'} />
        <h3>Ticket details</h3>
        { selectedTicket !== null ? <>
          <p><b>{allTickets[selectedTicket].type[0].toUpperCase() + allTickets[selectedTicket].type.slice(1)}</b> ticket. Admits {allTickets[selectedTicket].type === "single" ? '1 person' : '2 people'}.</p>
          <br />
          <p><b>Upgrades:</b></p>
          <p>Fast Track: <b>{allTickets[selectedTicket].upgrades.fasttrack ? 'Yes' : 'No'}</b></p>
          <p>Dining: <b>{allTickets[selectedTicket].upgrades.dining ? 'Yes' : 'No'}</b></p>
          <p>Breakfast: <b>{allTickets[selectedTicket].upgrades.breakfast ? 'Yes' : 'No'}</b></p>
          <br />
          <p>Price: <b>£{allTickets[selectedTicket].price}</b></p>
        </> : null}
      </RejectModal>
      
      <TicketGrid tickets={accTickets} ticketGroup={'accepted'} />

      <styles.Footer id="footer"></styles.Footer>
    </styles.PageWrapper>
  </>
}

export default Homepage;
