import { Theme as SelectTheme } from 'react-select';
import { Theme } from './Theme';

export const selectTheme = (theme: SelectTheme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Theme.colors.primaryFaded,
    primary: Theme.colors.primary,
  },
});