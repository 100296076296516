import { AccountStatus } from "../models/AccountStatus";


export interface AccountRules {
  accountStatus: AccountStatus;
  maxSingles: number;
  maxDoubles: number;
  applicationDeadline: Date;
  canUpgrade: boolean;
  recentlyGraduated?: boolean;
  donations: number[]
}

const rules: Record<string, AccountRules> = {
  [AccountStatus.CLUB1888]: {
    accountStatus: AccountStatus.CLUB1888,
    maxSingles: 0,
    maxDoubles: 10,
    applicationDeadline: new Date(Date.UTC(2022, 4, 15, 23, 59, 59, 0)),
    canUpgrade: false,
    donations: [10,20,50]
  },
  [AccountStatus.FELLOW]: {
    accountStatus: AccountStatus.FELLOW,
    maxSingles: 1,
    maxDoubles: 10,
    applicationDeadline: new Date(Date.UTC(2022, 4, 15, 23, 59, 59, 0)),
    canUpgrade: true,
    donations: [10,20,50]
  },
  [AccountStatus.EXTERNAL]: {
    accountStatus: AccountStatus.EXTERNAL,
    maxSingles: 0,
    maxDoubles: 1,
    applicationDeadline: new Date(Date.UTC(2022, 2, 11, 23, 59, 59, 0)),
    canUpgrade: true,
    donations: [5,10,25]
  },
  [AccountStatus.INTERNAL]: {
    accountStatus: AccountStatus.INTERNAL,
    maxSingles: 1,
    maxDoubles: 3,
    applicationDeadline: new Date(Date.UTC(2022, 2, 11, 23, 59, 59, 0)),
    canUpgrade: true,
    donations: [5,10,25]
  },
  "recent-graduate": {
    accountStatus: AccountStatus.ALUMNI,
    maxSingles: 1,
    maxDoubles: 3,
    applicationDeadline: new Date(Date.UTC(2022, 2, 18, 23, 59, 59, 0)),
    canUpgrade: true,
    recentlyGraduated: true,
    donations: [10,20,50]
  },
  "other-alumni": {
    accountStatus: AccountStatus.ALUMNI,
    maxSingles: 0,
    maxDoubles: 3,
    applicationDeadline: new Date(Date.UTC(2022, 2, 18, 23, 59, 59, 0)),
    canUpgrade: true,
    recentlyGraduated: false,
    donations: [10,20,50]
  },
  [AccountStatus.UNKNOWN]: {
    accountStatus: AccountStatus.UNKNOWN,
    maxSingles: 0,
    maxDoubles: 0,
    applicationDeadline: new Date(Date.UTC(2022, 2, 11, 23, 59, 59, 0)),
    canUpgrade: false,
    donations: [10,20,50]
  }
}

const getAccountRules = (accountStatus: AccountStatus, graduationYear?: string): AccountRules => {
  if (accountStatus === undefined || 'other') {
    return rules[AccountStatus.UNKNOWN];
  }
  
  if (accountStatus === AccountStatus.ALUMNI) {
    if (graduationYear === "2020" || graduationYear === "2021") {
      return rules["recent-graduate"]
    }
    return rules["other-alumni"]
  }
  return rules[accountStatus]
}

export { getAccountRules };
