import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useAuth } from './../contexts/auth';

import { selectTheme } from './../styles/selectTheme';

import * as styles from './../styles/homepage';

const Modal = styled(styles.ModalWrapper)`
  input {
    border: 1px solid hsl(0, 0%, 70%);
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
  }

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid hsl(0, 0%, 70%);
  }

  .radio-group {
    input[type='radio'] {
      margin-right: 0.5rem;
    }
  }

  a {
    text-decoration: underline;
  }
`;

const ColoredApply = styled(styles.Apply)`
  border-left: 5px solid ${props => props.theme.colors.primary};

  .columns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }


  .divider {
    border-top: 1px solid ${props => props.theme.colors.primary};
    margin: 1rem 0;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

const ColoredButton = styled(styles.SubtleButton)`
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};

  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const Waitlist: React.FC = () => {
  const { request, info } = useAuth();

  const [waitlistStatus, setWaitlistStatus] = useState(false);

  const getWaitlistStatus = async () => {
    const response = await request('/user/waitlist', 'GET');

    if (response.ok) {
      const { status } = await response.json();
      console.log(status);
      setWaitlistStatus(status as boolean);
    }
  }

  useEffect(() => {
    getWaitlistStatus();
  }, []);

  const updateWaitlistStatus = async (newStatus: boolean) => {
    const response = await request('/user/waitlist', 'POST', { status: newStatus });

    if (response.ok) {
      getWaitlistStatus();
    }
  }

  return <>
    <ColoredApply>
      <h3>Join the ticket waitlist.</h3>
      <p>Ticket applications are now closed. If any tickets get reallocated, they will be allocated to people on the waitlist.</p>
      <p><strong>{`You are ${waitlistStatus ? '' : 'not'} on the waitlist.`}</strong></p>

      <ColoredButton onClick={() => updateWaitlistStatus(!waitlistStatus)}>{`${waitlistStatus ? 'Leave' : 'Join'}`} the waitlist</ColoredButton>
    </ColoredApply>
  </>
}

export default Waitlist;