import React from 'react';
import { useAuth } from '../contexts/auth';

import * as styles from './TicketGrid.styles';

import { Ticket } from '../models/Ticket';

interface TicketGridProps {
  tickets: Ticket[],
  ticketGroup: 'applied' | 'allocated' | 'accepted',
  buttons?: {
    text: string,
    fn: (index: number) => void
  }[]
}

const TicketGrid: React.FC<TicketGridProps> = ({ tickets, ticketGroup, buttons = []}) => {
  const { request } = useAuth();

  const phrasing = (ticketGroup: 'applied' | 'allocated' | 'accepted') => {
    switch (ticketGroup) {
      case 'applied':
        return 'Ticket applications';
      case 'allocated':
        return 'Ticket allocations';
      case 'accepted':
        return 'Accepted tickets';
    }
  }

  const singles = ['single', 'additional_single', 'single_rolled'];
  const doubles = ['double', 'additional_double', 'double_rolled'];

  return <>
    <styles.Tickets id="tickets">
        <h3>{phrasing(ticketGroup)}:</h3>
        {tickets.length === 0 && <p>You currently have no {phrasing(ticketGroup)}.</p>}
        {tickets.length > 0 && <>
          <p>User currently have <b>{tickets.length}</b> {phrasing(ticketGroup)}.</p>
          <styles.TicketsGrid>
            {
              tickets.map((ticket, index) => {
                return <div key={index}>
                  <h3>Ticket {index + 1}</h3>
                  <p><b>{ticket.type[0].toUpperCase() + ticket.type.slice(1)}</b> ticket. Admits {doubles.includes(ticket.type) ? '2 people' : '1 person'}.</p>

                  <div className="divider" />

                  <p><b>Upgrades:</b></p>
                  <p>Fast Track: <b>{ticket.upgrades.fasttrack ? 'Yes' : 'No'}</b></p>
                  <p>Dining: <b>{ticket.upgrades.dining ? 'Yes' : 'No'}</b></p>
                  <p>Breakfast: <b>{ticket.upgrades.breakfast ? 'Yes' : 'No'}</b></p>

                  <div className="divider" />

                  { (ticket.price !== undefined && ticket.paid !== undefined) && <>
                    {/* <p><b>Price:</b></p> */}
                    <p>Price: <b>£{ticket.price}</b></p>
                    {/* <p>Paid: <b>£{ticket.paid}</b></p>
                    <p>Outstanding balance: <b>£{ticket.price - ticket.paid}</b></p> */}

                    <div className="divider" />
                  </>}
                  
                  { buttons.length > 0 && <> 
                    <p><b>Actions:</b></p>
                    <div className="button-group">
                      { buttons.map((button, ib) => <React.Fragment key={ib}>
                        <styles.SubtleButton onClick={() => button.fn(index)}>{button.text}</styles.SubtleButton>
                      </React.Fragment>)}
                    </div>
                  </>}
                </div>
              })
            }
          </styles.TicketsGrid>
        </>}
      </styles.Tickets>
  </>
}

export default TicketGrid;