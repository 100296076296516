import React from 'react';
import Select, { SingleValue } from 'react-select';
import { Ticket } from '../models/Ticket';
import { TicketType } from '../models/TicketType';
import { AccountStatus } from './../models/AccountStatus';
import { selectTheme } from './../styles/selectTheme';
import { AccountRules } from '../helpers/rules';

type option = { value: string; label: string; };

interface IProps {
  accountRules: AccountRules;
  appliedTickets: Ticket[];
  setTicketType: (ticketType: SingleValue<option>) => void;
}

const TicketTypeSelector: React.FC<IProps> = ({ accountRules, appliedTickets, setTicketType }) => {
  const doubleOption = { value: 'double', label: 'Double' };
  const singleOption = { value: 'single', label: 'Single' };

  const numAppliedDoubles = appliedTickets.filter(t => t.type === TicketType.DOUBLE).length;
  const numAppliedSingles = appliedTickets.filter(t => t.type === TicketType.SINGLE).length;

  const availableOptions: option[] = [];

  if (numAppliedDoubles < accountRules.maxDoubles) {
    availableOptions.push(doubleOption);
  }
  if (numAppliedSingles < accountRules.maxSingles) {
    availableOptions.push(singleOption);
  }

  const getDescription = (): string => {
    switch (accountRules.accountStatus) {
      case AccountStatus.INTERNAL:
        return "You may either apply for tickets as a double or a single. You can only apply for a single if that is the only ticket you apply for.";
      case AccountStatus.ALUMNI:
        return accountRules.recentlyGraduated ?
          "You are entitled to a single ticket at a reduced cost due to being a recent graduate, and can apply for up to 3 pairs also"
          : "You may apply for up to 3 alumni pairs.";
      case AccountStatus.FELLOW:
        return "Eligible resident fellows may apply for one single ticket free of charge. Otherwise you may only apply for doubles.";
      case AccountStatus.CLUB1888:
        return "You may apply for up to 10 doubles through this portal. For more, please email the head of tickets@mayball.org";
      default:
        return "Please confirm your ticket options."
    }
  }

  return <>
    <p>{getDescription()}</p>
    <label htmlFor={'type'}>Ticket type:</label>
    <Select
      name={'type'}
      onChange={setTicketType}
      theme={selectTheme}
      options={availableOptions}
    />
  </>
}

export default TicketTypeSelector;