import react, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useAuth } from './../contexts/auth';

import { selectTheme } from './../styles/selectTheme';

import * as styles from './../styles/homepage';

const Modal = styled(styles.ModalWrapper)`
  input {
    border: 1px solid hsl(0, 0%, 70%);
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;

  }
`;

const ColoredApply = styled(styles.Apply)`
  border-left: 5px solid ${props => props.theme.colors.secondary};
`;

const ColoredButton = styled(styles.SubtleButton)`
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

interface Option { value: string; label: string};

const ClubDetails: React.FC = () => {
  const { request, getUserData } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const [affiliation, setAffiliation] = useState({} as SingleValue<Option>);
  const [verification, setVerification] = useState('');

  const submitForm = async () => {
    if (!affiliation || !verification) return;

    const res = await request('/user/1888/verification', 'POST', {
      affiliation: affiliation.value,
      verification: verification
    });

    if (res.status === 200) {
      setShowModal(false);
      getUserData(true);
    }
  }

  return <>
    {showModal && <>
      <Modal>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>1888 Club Verification</h2>
            <styles.CloseButton onClick={() => setShowModal(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            {/* <h3>:</h3> */}

            <label htmlFor="affiliation">Affiliation:</label>
            <Select
              name={'type'}
              onChange={setAffiliation}
              theme={selectTheme}
              options={[
                { value: 'alumni', label: 'Alumni' },
                { value: 'fellow', label: 'Fellow' },
                { value: 'staff', label: 'Staff' },
                { value: 'student', label: 'Student' },
              ]}
            /> 

            <br />
            <br />

            { affiliation?.value && <h3>Verification details:</h3> }

            {/* Affiliation specific questions */}
            {affiliation?.value === 'alumni' && <>
              <p>Please provide your matriculation year for verification.</p>
              <label htmlFor="verification">Matriculation year:</label>
            </>}

            {affiliation?.value === 'fellow' && <>
              <p>Please provide your crsid for verification.</p>
              <label htmlFor="verification">Crsid:</label>
            </>}

            {affiliation?.value === 'staff' && <>
              <p>Please provide your crsid or college email for verification.</p>
              <label htmlFor="verification">Crsid or email:</label>
            </>}

            {affiliation?.value === 'student' && <>
              <p>Please provide your crsid for verification.</p>
              <label htmlFor="verification">Crsid:</label>
            </>}

            { affiliation?.value && <input type="text" name="verification" value={verification} onChange={e => setVerification(e.target.value)} /> }

            <br />

            <styles.SubmitButton
              color={'primary'}
              onClick={submitForm}
              disabled={!affiliation?.value || !verification}
            >
              Submit
            </styles.SubmitButton>

          </styles.ModalBody>
        </styles.ModalBox>
      </Modal>

    </>}


    <ColoredApply>
      <h3>1888 Club verification.</h3>
      <p>In order for your account to be verified, we need to collect a few pieces of information. This should only take a couple of minutes; please complete it at your earliest convenience.</p>
      <ColoredButton onClick={() => setShowModal(true)}>Verify</ColoredButton>
    </ColoredApply>
  </>
}

export default ClubDetails;