import react, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useAuth } from './../contexts/auth';
import Poster from './../assets/poster.jpg';

import { selectTheme } from './../styles/selectTheme';

import * as styles from './../styles/homepage';

const Modal = styled(styles.ModalWrapper)`
  input {
    border: 1px solid hsl(0, 0%, 70%);
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
  }

  .modal-poster {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .poster {
    width: 30%;

    img {
      width: 100%;
    }

    @media (max-width: 900px) {
      width: 50%;
      margin: auto;
    }
  }

  .info {
    flex-grow: 1;
    & > * {
      display: block;
    }

    label {
      margin-bottom: 0.5rem;
    }

    .divider {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border-top: 1px solid hsl(0, 0%, 70%);
    }
  }
`;

const ColoredApply = styled(styles.Apply)`
  border-left: 5px solid ${props => props.theme.colors.secondary};
`;

const ColoredButton = styled(styles.SubtleButton)`
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const Posters: React.FC = () => {
  const { request, getUserData } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(0);

  const [quantityApplied, setQuantityApplied] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState('');

  const getPosterOrders = async () => {
    const res = await request('/order/poster', 'GET');

    if (res.status === 200) {
      const data = await res.json();

      setQuantityApplied(data.quantity);
      setQuantity(data.quantity);
    }
  }

  const submitForm = async () => {
    if (isLoading) return;

    setIsLoading(true);
    if (quantity === 0 && quantityApplied === 0) return;

    const res = await request('/order/poster', 'POST', {
      quantity: quantity
    });

    if (res.ok) {
      setShowModal(false);
      getPosterOrders();
    } else {
      const data = await res.json();
      setModalError(data.error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getPosterOrders();
  }, []);


  return <>
    {showModal && <>
      <Modal>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>Purchase poster</h2>
            <styles.CloseButton onClick={() => setShowModal(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            <div className="modal-poster">
              <div className="poster">
                <img src={Poster} />
              </div>
              <div className="info">
                <h3>Buy a May Ball poster</h3>
                <p>One of the best ways to keep the memory of the May Ball, is with a poster.</p>
                <p>Posters cost <b>£5</b>, and collection will be in Cambridge in June.</p>

                <div className="divider" />

                {quantityApplied > 0 && <>
                  <p>You have already applied for <b>{quantityApplied}</b> poster(s).</p>
                  <p>If you want to edit the number of posters applied for, please resubmit the form below.</p>
                  <div className="divider" />
                </>}

                <h3>Order:</h3>
                <label htmlFor="quantity">Quantity:</label>
                <input
                  type="number"
                  name="quantity"
                  min="0"
                  max="10"
                  value={quantity}
                  onChange={e => setQuantity(Number(e.target.value))}
                />

                <p>Cost: <b>£{quantity * 5}</b></p>

                { modalError && <p className="error">{modalError}</p> }

                <styles.SubmitButton
                  color={'primary'}
                  onClick={submitForm}
                  disabled={(quantity === 0 && quantityApplied === 0) || isLoading}
                >
                  {!isLoading ? 'Submit' : 'Submitting...'}
                </styles.SubmitButton>

              </div>
            </div>
          </styles.ModalBody>
        </styles.ModalBox>
      </Modal>

    </>}


    <ColoredApply>
      <h3>May Ball Posters.</h3>
      <p>You can now buy a printed poster for the 2022 May Ball. Posters cost <b>£5</b>, and will be available for collection in Cambridge in June.</p>
      
      {quantityApplied > 0 && <>
        <p>You have already applied for <b>{quantityApplied}</b> poster(s).</p>
        <p>If you want to edit the number of posters applied for, please resubmit the form below.</p>
        <div className="divider" />
      </>}

      <ColoredButton onClick={() => setShowModal(true)}>Buy poster</ColoredButton>
    </ColoredApply>
  </>
}

export default Posters;