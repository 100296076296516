import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useAuth } from './../contexts/auth';

import { AccountRules, getAccountRules } from '../helpers/rules';
import { AccountStatus } from '../models/AccountStatus';

import { selectTheme } from './../styles/selectTheme';

import * as styles from './../styles/homepage';

const Modal = styled(styles.ModalWrapper)`
  input {
    border: 1px solid hsl(0, 0%, 70%);
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
  }

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid hsl(0, 0%, 70%);
  }

  .radio-group {
    input[type='radio'] {
      margin-right: 0.5rem;
    }
  }

  a {
    text-decoration: underline;
  }
`;

const ColoredApply = styled(styles.Apply)`
  border-left: 5px solid ${props => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const ColoredButton = styled(styles.SubtleButton)`
  color: ${props => props.theme.colors.secondary};
  width: max-content;
  border: 1px solid ${props => props.theme.colors.secondary};

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const Posters: React.FC = () => {
  const { request, info } = useAuth();

  const accountRules = getAccountRules(info.status as AccountStatus);

  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState('');

  const [donationRadio, setDonationRadio] = useState<number|null|'other'>(null);
  const [otherDonationAmount, setOtherDonationAmount] = useState(0);
  const [existingDonation, setExistingDonation] = useState(0);

  const getDonation = async () => {
    const res = await request('/order/donation', 'GET');

    if (res.status === 200) {
      const data = await res.json();
      setExistingDonation(data.value);
    }
  }

  const submitForm = async () => {
    if (isLoading) return;
    if ((donationRadio === null ||
    (donationRadio === 'other' && (otherDonationAmount === 0 && existingDonation === 0)))
    || isLoading) { return; }

    setIsLoading(true);

    const res = await request('/order/donation', 'POST', {
      donation: donationRadio === 'other' ? otherDonationAmount : donationRadio
    });

    if (res.ok) {
      setShowModal(false);
      getDonation();
    } else {
      const data = await res.json();
      setModalError(data.error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getDonation();
  }, []);

  useEffect(() => {
    if (!showModal) {
      // Reset state
      setDonationRadio(null);
      setOtherDonationAmount(0);
      setModalError('');
    }
  }, [showModal]);

  return <>
    {showModal && <>
      <Modal>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>Charity donations</h2>
            <styles.CloseButton onClick={() => setShowModal(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            <h3>Donate to one of the May Ball's nominated charities</h3>
            <p>The St John's College May Ball is proud to support local, national and internation charities.</p><p>This years nominated charities are:</p>
            <p>-<b> <a target="_blank" href="https://cambridgerapecrisis.org.uk">Cambridge Rape Crisis Centre</a></b></p>
            <p>-<b> <a target="_blank" href="https://www.trusselltrust.org">The Trussell Trust</a></b></p>
            <p>-<b> <a target="_blank" href="https://www.solidaritee.org.uk">SolidariTee</a></b></p>

            <p>Donations will be made alongside payment for tickets.</p>
            <div className="divider" />

            {existingDonation > 0 && <>
              <p>Your current donation is <b>£{existingDonation}</b>. To update it, please resubmit the form below.</p>
              <div className="divider" />
            </> }


            <h3>Donation amount:</h3>
            <div className="radio-group">
              {accountRules.donations.map((donation,i) => <React.Fragment key={i}>
                <p onClick={() => setDonationRadio(donation)}>
                  <input
                    type="radio"
                    value={donation}
                    name="donation"
                    checked={donationRadio === donation}
                  />
                  <b>£{donation}</b>
                </p>
                
              </React.Fragment>)}

              <p onClick={() => setDonationRadio('other')}>
                <input
                  type="radio"
                  value={'other'}
                  name="donation"
                  checked={donationRadio === 'other'}
                />  
                <b>Other</b>
              </p>

              {donationRadio === 'other' && <input
                type="number"
                value={otherDonationAmount}
                onChange={(e) => setOtherDonationAmount(Number(e.target.value))}
              />}

              
            </div>

            { modalError && <p className="error">{modalError}</p> }

            <styles.SubmitButton
              color={'primary'}
              onClick={submitForm}
              disabled={
                (donationRadio === null ||
                (donationRadio === 'other' && (otherDonationAmount === 0 && existingDonation === 0)))
                || isLoading
              }
            >
              {!isLoading ? 'Submit' : 'Submitting...'}
            </styles.SubmitButton>
          </styles.ModalBody>
        </styles.ModalBox>
      </Modal>

    </>}


    <ColoredApply>
      <h3>Charity donations.</h3>
      <p>The St John's College May Ball is proud to support local, national and internation charities. To find out more, and if you would like to include a donation alongside your ticket applications then please use the form below.</p>
      
      {existingDonation > 0 && <p>Your current donation is <b>£{existingDonation}</b>. To update it, please resubmit the form below.</p>}

      <ColoredButton onClick={() => setShowModal(true)}>Add donation</ColoredButton>
    </ColoredApply>
  </>
}

export default Posters;