import React from 'react';
import { AccountRules } from '../helpers/rules';
import { AccountStatus } from '../models/AccountStatus';
import { Info as InfoStyle } from './../styles/homepage';

interface IProps {
  accountRules: AccountRules;
}

const AccountSpecificInfo: React.FC<IProps> = ({ accountRules }) => {
  
  const genSpecificInfo = (account: string) => {
    switch (account) {
      case AccountStatus.FELLOW:
        return <>
          <p>Those wishing for upgrades on tickets should apply before March 18th, else these may become oversubscribed.</p>
          <p>Should you wish to apply for more than 10 pairs, please contact the head of ticketing (tickets@mayball.org).</p>
        </>
      case AccountStatus.CLUB1888:
        return <>
          <p>The 1888 Club offers a unique experience at the May Ball.
            1888 members are greeted with a champagne reception on arrival and gain access to the exclusive Wordsworth room, where the poet lived as an undergraduate at the college over 450 years ago.
            Here they will be welcomed with a curated selection of food and drinks, along with live music and entertainment throughout the night.</p>
          <p>Depending on availability, it may be possible to apply for more tickets after the official deadline has passed.
            Please contact the head of ticketing (tickets@mayball.org) to discuss in this case.</p>
        </>
      default:
        return <></>
    }
  }
  
  return <InfoStyle>
    <h3>Information about the ticket application process:</h3>
    <p>The application deadline for {accountRules.accountStatus} accounts is: {accountRules.applicationDeadline.toUTCString()}.</p>
    {genSpecificInfo(accountRules.accountStatus)}
    <p>Please see our <a target="_blank" href="https://mayball.org/tickets">FAQ</a> for more information.</p>
  </InfoStyle>
}

export default AccountSpecificInfo;
