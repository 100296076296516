import React, { useState, useEffect } from 'react';

import * as styles from './AcceptCancelModal.styles';

interface AcceptRejectModalProps {
  title: string;
  acceptFn: () => void;
  rejectFn: () => void;
}

const useAcceptRejectModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  }

  const close = () => {
    setIsOpen(false);
  }

  const Modal: React.FC<AcceptRejectModalProps> = ({title, acceptFn, rejectFn, children}) => <>
    {isOpen && <>
      <styles.ModalWrapper>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>{title}</h2>
            <styles.CloseButton onClick={() => setIsOpen(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            { children }
          </styles.ModalBody>
          <styles.Divider />
          <styles.ModalFooter>
            <styles.Button onClick={() => acceptFn()}>Confirm</styles.Button>
            <styles.CancelButton onClick={() => rejectFn()}>Cancel</styles.CancelButton>
          </styles.ModalFooter>
        </styles.ModalBox>
      </styles.ModalWrapper>
    </>}
  </>

  return [Modal, open, close] as const;
}

export default useAcceptRejectModal;