import React from "react";
import Select, { SingleValue } from "react-select";
import { AccountRules } from "../helpers/rules";
import { selectTheme } from "../styles/selectTheme";

type selectOption = SingleValue<{ value: string; label: string; }>

interface IProps {
  accountRules: AccountRules;
  setFastTrack: (fastTrack: selectOption) => void;
  setDining: (dining: selectOption) => void;
  setBreakfast: (breakfast: selectOption) => void;
}

const TicketUpgradeSelector: React.FC<IProps> = ({ accountRules, setFastTrack, setDining, setBreakfast }) => {
  if (!accountRules.canUpgrade) {
    return <>
      <h3>Upgrades:</h3>
      <p>This ticket is already fully upgraded.</p>
    </>
  }

  const options = {
    fastTrack: [
      { value: 'false', label: 'No Fast Track Upgrade' },
      { value: 'true', label: 'Fast Track Upgrade' }
    ],
    dining: [
      { value: 'false', label: 'No Dining Upgrade' },
      { value: 'true', label: 'Dining Upgrade' }
    ],
    breakfast: [
      { value: 'false', label: 'No Breakfast Upgrade' },
      { value: 'true', label: 'Breakfast Upgrade' },
    ]
  };

  return <>
    <h3>Upgrades:</h3>
    <p>Unfortunately tickets cannot be upgraded with both Dining and Fast Track. This is due to the dinner and Fast Track reception occurring at the same time before the ball.</p>
    <label htmlFor={'fasttrack'}>Fast Track:</label>
    <p>Start your evening off in style an hour early with a reception. (£100/pair)</p>
    <Select
      defaultValue={options.fastTrack[0]}
      name={'fasttrack'}
      onChange={setFastTrack}
      theme={selectTheme}
      options={options.fastTrack}
    />

    <label htmlFor="dining">Dining:</label>
    <p>Begin your night in luxury with a delicious meal in the historic Combination Room. (£175/pair)</p>
    <Select
      defaultValue={options.dining[0]}
      name={'dining'}
      onChange={setDining}
      theme={selectTheme}
      options={options.dining}
    />

    <label htmlFor="breakfast">Breakfast:</label>
    <p>Take a breather from the ball in the early hours of the morning. (£95/pair)</p>
    <Select
      defaultValue={options.breakfast[0]}
      name={'breakfast'}
      onChange={setBreakfast}
      theme={selectTheme}
      options={options.breakfast}
    />
  </>
}

export default TicketUpgradeSelector;