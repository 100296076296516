import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useAuth } from './../contexts/auth';

import { AccountRules, getAccountRules } from '../helpers/rules';
import { AccountStatus } from '../models/AccountStatus';

import { selectTheme } from './../styles/selectTheme';

import * as styles from './../styles/homepage';

const Modal = styled(styles.ModalWrapper)`
  input {
    border: 1px solid hsl(0, 0%, 70%);
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
  }

  .divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid hsl(0, 0%, 70%);
  }

  .radio-group {
    input[type='radio'] {
      margin-right: 0.5rem;
    }
  }

  a {
    text-decoration: underline;
  }
`;

const ColoredApply = styled(styles.Apply)`
  border-left: 5px solid ${props => props.theme.colors.primary};

  .columns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }


  .divider {
    border-top: 1px solid ${props => props.theme.colors.primary};
    margin: 1rem 0;
  }

  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`;

const ColoredButton = styled(styles.SubtleButton)`
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};

  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
`;

const Finance: React.FC = () => {
  const { request, info } = useAuth();

  const accountRules = getAccountRules(info.status as AccountStatus);

  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [modalError, setModalError] = useState('');

  useEffect(() => {
    if (!showModal) {
      // Reset state
      setModalError('');
    }
  }, [showModal]);

  // Calculate the total owed

  const [totalOwed, setTotalOwed] = useState(0);

  const getFinanceInfo = async () => {
    const res1 = await request('/order/donation', 'GET');
    const donation = await res1.json();

    const res2 = await request('/order/poster', 'GET');
    const posters = await res2.json();

    const res3 = await request('/tickets', 'GET');
    const tickets = await res3.json();

    // Add up
    let total = 0;
    tickets.accepted.forEach((t: any) => {
      total += Number(t.price);
    });

    total += (posters.quantity * 5);
    total += donation.value;

    setTotalOwed(total);
  }

  useEffect(() => {
    getFinanceInfo();
  }, []);

  return <>
    {showModal && <>
      <Modal>
        <styles.ModalBox>
          <styles.ModalHeader>
            <h3>St John's College May Ball</h3>
            <h2>Payment history</h2>
            <styles.CloseButton onClick={() => setShowModal(false)}>Close</styles.CloseButton>
          </styles.ModalHeader>
          <styles.Divider />
          <styles.ModalBody>
            <h3>History of payments into your account.</h3>
            <p>Payments may take up to a week to show on your account.</p>
            <p>If a payment has still not shown up after a week, please contact <a href="mailto:treasurer@mayball.org">our Treasurer</a>.</p> 
            <div className="divider" />
            <h3>Payments:</h3>

            {info.payment_history.length === 0 && <p>There have been no payments into your account.</p>}
            
            {info.payment_history.map((payment: any, i: number) => <React.Fragment key={i}>
              <p>{payment.date} - {payment.amount}</p>
            </React.Fragment>)}
          </styles.ModalBody>
        </styles.ModalBox>
      </Modal>

    </>}


    <ColoredApply>
      <h3>Payment information.</h3>
      <div className="columns">
        <div>
          <p>You have accepted tickets on your account - please pay the required amount by the payment deadline.</p>
          
          <p><b>First ticket deadline:</b> Friday 15th April, 11:59PM</p>
          <p><b>Additional ticket deadline:</b> Saturday 30th April, 11:59PM</p>
          
          <p className="divider" />
          
          <p>Payment may be made by bank transfer only and <strong>must contain your payment reference</strong>.</p>
          <p>If your transfer does not have your payment reference ({info.payment_reference}) on it, then we may be unable to reconcile the payment with your account. Other accounts may make transfers on your behalf by using your payment reference.</p>
          <p>Your payment may take up to a week to show on your account.</p>
          <p>If you have any problems or queries regarding payment then please contact <a href="mailto:treasurer@mayball.org">our Treasurer</a>.</p>
        </div>

        <div>
          <p>The total charges to your account are: <b>{`£${totalOwed.toFixed(2)}`}</b></p>
          <p>The total credits to your account are: <b>{`£${info.balance.toFixed(2)}`}</b></p>
          <br />
          <p>The remaining balance to pay is: <b>{`£${(totalOwed - info.balance).toFixed(2)}`}</b></p>

          <div className="divider" />

          <p>Payment reference: <b>{info.payment_reference}</b></p>
          <p>Account name: <b>St John's College May Ball</b></p>
          <p>Account number: <b>35381560</b></p>
          <p>Sort code: <b>30-64-42</b></p>
          <p>IBAN: <b>GB51LOYD30644235381560</b></p>
          <p>SWIFT: <b>LOYDGB21670</b></p>
        </div>
      </div>
      

      <p><b>Any tickets not accepted and paid for by the relevant deadlines will be voided and reallocated.</b></p>
      <p>The payment for the ticket marked "Ticket 1", must be accepted and paid for by the first ticket deadline, and all other tickets must be paid for by the additional ticket deadline.</p>

      <ColoredButton onClick={() => setShowModal(true)}>Payment history</ColoredButton>
    </ColoredApply>
  </>
}

export default Finance;