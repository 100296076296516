import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 90%;
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 2rem;

  min-height: 100vh;

  & > * {
    margin-bottom: 2rem;
  }
`;

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: block;

    a {
      display: inline-block;
      margin-top: 0.5rem;
    }
  }

  margin-bottom: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid ${props => props.theme.colors.primary};
  
  h1 {
    color: ${props => props.theme.colors.primary};
  }

  a {
    font-size: ${props => props.theme.fontSizes.small};
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.background};

    text-decoration: none;

    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    padding: 0.5rem;

    outline: none;

    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.background};
    }
  }
`;

export const Info = styled.div`
  & > * {
    margin-bottom: 1rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
  }
`;

export const SubtleButton = styled.button`
  font-size: ${props => props.theme.fontSizes.small};
  font-family: ${props => props.theme.fonts.body};
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.background};

  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  padding: 0.3rem 0.5rem;

  outline: none;

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.background};
  }
`;

export const Apply = styled.div`
  border-left: 3px solid ${props => props.theme.colors.primary};
  padding-left: 1rem;

  & > * {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Tickets = styled.div``;

export const TicketsGrid = styled.div`
  margin-top: 1rem;
  & > div {
    /* border-left: 3px solid ${props => props.theme.colors.primary};
    padding-left: 1rem; */
    margin-bottom: 1rem;

    /* border-radius: 2px; */
    /* border: 1px #cccccc solid; */
    border-top: 4px solid ${props => props.theme.colors.primary};
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,0.1),0 0px 0 1px rgba(10,10,10,0.02);
    padding: 10px 20px;

    &:last-child {
      margin-bottom: 0;
    }

  }

  .divider {
    border-bottom: 1px solid ${props => props.theme.colors.primary};

    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Footer = styled.div``;

export const Form = styled.div`
  & > * {
    display: block;
  }
  & button {
    margin-top: 1rem;
  }
  input, textarea {
    margin: 0.4rem 0;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    color: #333;
    max-width: 100%;
    &:focus {
      outline: none !important;
      border: 2px solid ${props => props.theme.colors.primary};
    }
  }
`;

interface SubmitButtonProps {
  disabled?: boolean;
  color?: string;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
  background-color: white;
  border: 1px solid ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
  border-radius: 4px;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  padding: 5px;
  cursor: pointer;
  ${props => props.disabled && `
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      
    }
  `}
  &:hover {
    ${props => !props.disabled && `
      color: white;
      background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
    `}
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;  
`;

export const ModalBox = styled.div`
  background-color: white;
  width: 70%;
  max-width: 80rem;
  min-height: 20rem;
  padding: 3rem;
  position: relative;
  border-radius: 0.5rem;

  @media (max-width: 900px) {
    padding: 1rem;
    overflow-y: scroll;
    position: fixed;
    top: 1rem;
    left: 1rem;
    height: calc(100vh - 2rem);
    width: calc(100vw - 2rem);
    
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 0.8rem 0;
`;

export const ModalHeader = styled.div`
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    color: ${props => props.theme.colors.primary};
  }
`;

export const ModalBody = styled.div`
  p {
    margin-bottom: 0.4rem;
  }
  p.danger {
    color: #b60909;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.heading};
  }
  & > * {
    display: block;
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  label {
    font-weight: 700;
  }
  input, textarea {
    margin-bottom: 0.4rem;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.secondary};
    color: #333;
    max-width: 100%;
  }
  textarea {
    min-width: 60%;
    max-width: 100%;
    min-height: 10rem;
    resize: none;
  }

  h3 {
    font-size: 1rem;
    color: ${props => props.theme.colors.primary};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${props => props.theme.colors.primary};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.primary};
  }
`;